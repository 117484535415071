import * as at from "./types";

/**
 * These are mappings from Action -> Functions that call the corresponding authorization service method
 */
export const actionCalls = Object.freeze({
  [at.GET_IDENTITY]: (svc, { queryFilter }) => svc.getMyIdentity(queryFilter),
  [at.CREATE_IDENTITY]: (svc, { identity }) => svc.createIdentity(identity),
  [at.SEARCH_IDENTITY_IDENTIFIER]: (svc, params) =>
    svc.searchIdentityIdentifier(
      params.identifierName,
      params.identifierValues
    ),
  [at.UPDATE_IDENTITY]: (svc, { identity }) => svc.updateIdentity(identity),
  [at.DELETE_IDENTITY]: (svc, { identityid }) => svc.deleteIdentity(identityid),
  [at.GET_MY_GROUPS]: (svc, { queryFilter }) => svc.getMyGroups(queryFilter),
  [at.GET_GROUP_BY_ID]: (svc, params) =>
    svc.getGroupById(params.id, params.fields),
  [at.PATCH_GROUP_BY_ID]: (svc, params) =>
    svc.patchComputingGroup(params.groupId, params.fields),
  [at.GET_ADMIN_GROUP_BY_ID]: (svc, params) =>
    svc.getGroupById(params.id, params.fields),
  [at.GET_ACCOUNT_BY_ID]: (svc, params) => svc.getAccountById(params.id),
  [at.GET_ACCOUNT_PROVIDERS]: (svc, { queryFilter }) =>
    svc.getAccountProviders(queryFilter),
  [at.GET_CURRENT_IDENTITY_GROUPS]: (svc, { queryParams }) =>
    svc.getCurrentIdentityGroups(queryParams),
  [at.SUBSCRIBE_CURRENT_IDENTITY_TO_GROUPS]: (svc, { body }) =>
    svc.subscribeCurrentIdentityToGroups(body),
  [at.UNSUBSCRIBE_CURRENT_IDENTITY_FROM_GROUPS]: (svc, { body }) =>
    svc.unsubscribeCurrentIdentityFromGroups(body),
  [at.GET_CURRENT_IDENTITY_GROUPS_RECURSIVELY]: (svc, { queryParams }) =>
    svc.getCurrentIdentityGroupsRecursively(queryParams),
  [at.GET_CURRENT_IDENTITY_ACCOUNTS]: (svc) => svc.getCurrentIdentityAccounts(),
  [at.GET_CURRENT_PRIMARY_ACCOUNT]: (svc) =>
    svc.getCurrentIdentityPrimaryAccount(),
  [at.SET_PRIMARY_ACCOUNT]: (svc, { accountId }) =>
    svc.setPrimaryAccount(accountId),
  [at.GET_REQUESTS]: (svc, params) => svc.getRequests(params.queryFilter),
  [at.GET_REQUESTS_TO_APPROVE]: (svc, params) =>
    svc.getRequestsToApprove(params.queryFilter),
  [at.CANCEL_REQUEST]: (svc, params) => svc.cancelRequest(params.id),
  [at.DENY_REQUEST]: (svc, params) => svc.denyRequest(params.id, params.reason),
  [at.APPROVE_REQUEST]: (svc, params) =>
    svc.approveRequest(params.id, params.reason, params.approvalToken),
  [at.SEARCH_IDENTITY]: (svc, params) =>
    svc.searchIdentity(params.name, params.identityType),
  [at.SEARCH_ALL_IDENTITIES]: (svc, params) =>
    svc.searchAllIdentities(params.queryFilter),
  [at.RESOLVE_IDENTITY]: (svc, params) =>
    svc.resolveIdentity(params.identityid),
  [at.RESOLVE_AUDITS_FOR_ENTITY]: (svc, { id, queryFilter }) =>
    svc.resolveAuditsForEntity(id, queryFilter),
  [at.GET_AUDIT_OPERATION_DETAILS]: (svc, { id }) =>
    svc.getAuditOperationDetails(id),
  [at.GET_AUDIT_OPERATION_BY_ID]: (svc, { id }) =>
    svc.getAuditOperationById(id),
  [at.RESOLVE_APPLICATION_MANAGER_IDENTITY]: (svc, params) =>
    svc.resolveIdentity(params.identityid),
  [at.ASSOCIATE_IDENTITY]: (svc, params) => svc.associateIdentity(params.other),
  [at.SERVICE_DESK_ASSOCIATE_IDENTITY]: (svc, { targetId, otherId }) =>
    svc.serviceDeskAssociateIdentity(targetId, otherId),
  [at.SET_APPLICATION_STATUS]: (svc, { appId, payload }) =>
    svc.setAppStatus(appId, payload),
  [at.DELETE_ACCOUNT_FROM_IDENTITY]: (svc, params) =>
    svc.deleteAccountFromIdentity(params.accountid),
  [at.GET_APPLICATION_LIST]: (svc, params) =>
    svc.getApplicationList(params.queryFilter),
  [at.GET_ALL_APPLICATIONS]: (svc, params) =>
    svc.getAllApplications(params.queryFilter),
  [at.DELETE_APPLICATION]: (svc, params) =>
    svc.deleteApplication(params.applicationid),
  [at.ADD_APPLICATION]: (svc, params) => svc.addApplication(params.application),
  [at.EDIT_APPLICATION]: (svc, params) =>
    svc.editApplication(params.application),
  [at.ADD_ROLE]: (svc, params) => svc.addRole(params.role),
  [at.DELETE_ROLE]: (svc, params) =>
    svc.deleteRole(params.applicationid, params.roleid),
  [at.UPDATE_ROLE]: (svc, params) =>
    svc.updateRole(params.applicationid, params.roleid, params.role),
  [at.GET_APPLICATION]: (svc, params) =>
    svc.getApplication(params.applicationid),
  [at.GET_LIFECYCLESETTINGS_FOR_APPLICATION]: (svc, params) =>
    svc.getApplicationLifecycleSettings(params.applicationid),
  [at.GET_APPLICATION_FOR_MANAGED_RESOURCE_TYPE]: (svc, params) =>
    svc.getApplicationByManagedResourceType(params.managedResourceType),
  [at.GET_ALL_APPLICATIONS_V2]: (svc, params) =>
    svc.getAllApplicationsV2(params.queryFilter),
  [at.GET_APPLICATION_LIST_V2]: (svc, params) =>
    svc.getApplicationListV2(params.queryFilter),
  [at.GET_ROLES]: (svc, params) => svc.getRoles(params.applicationid),
  [at.GET_REGISTRATIONS]: (svc, params) =>
    svc.getRegistrationsForApplication(params.applicationid),
  [at.GET_PROVIDERS]: (svc) => svc.getProviders(),
  [at.REGISTER_APPLICATION]: (svc, params) =>
    svc.registerApplication(
      params.applicationid,
      params.providerid,
      params.details
    ),
  [at.DELETE_REGISTRATION]: (svc, params) =>
    svc.deleteRegistration(params.registrationid),
  [at.GET_GROUPS]: (svc) => svc.getGroups(),
  [at.GET_GROUPS_FOR_IDENTITY]: (svc, params) =>
    svc.getGroupsForIdentity(params.identityId, params.queryFilter),
  [at.GET_GROUPS_FOR_ROLE]: (svc, params) =>
    svc.getGroupsForRole(params.applicationid, params.roleid),
  [at.GET_ROLE_FOR_APPLICATION]: (svc, params) =>
    svc.getRoleForApplication(params.applicationid, params.roleid),
  [at.ADD_GROUP_TO_ROLE]: (svc, params) =>
    svc.addGroupToRole(params.applicationid, params.roleid, params.groupid),
  [at.DELETE_GROUP_FROM_ROLE]: (svc, params) =>
    svc.deleteGroupFromRole(
      params.applicationid,
      params.roleid,
      params.groupid
    ),
  [at.DISPLAY_SECRET]: (svc, { registrationid }) =>
    svc.displaySecret(registrationid),
  [at.GET_ROLE_BY_NAME]: (svc, { roleName, applicationId }) =>
    svc.getRoleByName(roleName, applicationId),
  [at.GET_GROUPS_FOR_ROLE_BY_ID]: (svc, { roleId }) =>
    svc.getGroupsAssignedToRole(roleId),
  [at.REGENERATE_SECRET]: (svc, params) =>
    svc.regenerateSecret(params.registrationid, params.providerid),
  [at.SEARCH_GROUP]: (svc, params) => svc.searchGroup(params.queryFilter),
  [at.GET_IDENTITY_BY_ID]: (svc, params) =>
    svc.getIdentityById(params.id, params.queryFilter),
  [at.SD_RENAME_IDENTITY]: (svc, params) =>
    svc.serviceDeskRenameIdentity(params.identityId, params.newName),
  [at.GET_IDENTITY_MEMBERSHIPS]: (svc, { identityId, queryFilter = null }) =>
    svc.getIdentityMemberships(identityId, queryFilter),
  [at.SD_EXTEND_ACCOUNT_EXPIRATION]: (svc, params) =>
    svc.serviceDeskExtendAccountExpiration(params.newAccount),
  [at.CREATE_STATIC_GROUP]: (svc, params) => svc.createStaticGroup(params),
  [at.SEARCH_GROUP_IDENTIFIER]: (svc, params) =>
    svc.searchGroupIdentifier(params.identifierName, params.identifierValues),
  [at.UPDATE_GROUP]: (svc, params) => svc.updateGroup(params.id, params.group),
  [at.GET_GROUPS_I_OWN]: (svc, { queryFilter }) =>
    svc.getGroupsIOwn(queryFilter),
  [at.GET_GROUP_MEMBER_IDENTITIES]: (svc, params) =>
    svc.getGroupMemberIdentities(params.id, params.queryFilter),
  [at.GET_GROUP_MEMBER_IDENTITIES_WITH_COMMENTS]: (svc, params) =>
    svc.getGroupMemberIdentitiesWithComments(params.id, params.queryFilter),
  [at.UPDATE_GROUP_MEMBER_IDENTITY_COMMENT]: (
    svc,
    { groupId, identityMemberId, comment }
  ) => svc.updateGroupMemberIdentityComment(groupId, identityMemberId, comment),
  [at.TRANSFER_GROUP_OWNER]: (svc, params) =>
    svc.transferGroupOwnership(params.groupId, params.identityId),
  [at.TRANSFER_APPLICATION_OWNER]: (svc, params) =>
    svc.transferApplicationOwnership(params.applicationId, params.identityId),
  [at.DELETE_GROUP_BY_ID]: (svc, params) => svc.deleteGroupById(params.groupId),
  [at.GET_GROUP_MEMBER_GROUPS]: (svc, params) =>
    svc.getGroupMemberGroups(params.groupId, params.queryFilter),
  [at.GET_GROUP_MEMBER_GROUPS_WITH_COMMENTS]: (svc, params) =>
    svc.getGroupMemberGroupsWithComments(params.groupId, params.queryFilter),
  [at.UPDATE_GROUP_MEMBER_GROUP_COMMENT]: (
    svc,
    { groupId, groupMemberId, comment }
  ) => svc.updateGroupMemberGroupComment(groupId, groupMemberId, comment),
  [at.ADD_GROUP_MEMBER_GROUPS]: (svc, params) =>
    svc.addGroupMemberGroups(params.groupId, params.memberGroupIds),
  [at.ADD_GROUP_MEMBER_IDENTITIES]: (svc, params) =>
    svc.addGroupMemberIdentities(params.groupId, params.identityIds),
  [at.DELETE_GROUP_MEMBER_GROUPS]: (svc, params) =>
    svc.deleteGroupMemberGroups(params.groupId, params.memberGroupIds),
  [at.DELETE_GROUP_MEMBER_IDENTITIES]: (svc, params) =>
    svc.deleteGroupMemberIdentities(params.groupId, params.identityIds),
  [at.GET_GROUP_MEMBER_GROUPS_FOR_TREE_VIEW]: (svc, params) =>
    svc.getGroupMemberGroups(params.groupId, params.queryFilter),
  [at.GET_GROUP_PARENT_FOR_TREE_VIEW]: (svc, params) =>
    svc.searchOneGroup(params.groupId, params.queryFilter),
  [at.LOGOUT]: (svc) => svc.logout(),
  [at.UPDATE_REGISTRATION]: (svc, { providerid, details }) =>
    svc.updateRegistration(providerid, details),
  [at.GET_ALL_MY_APP_ROLES]: (svc, { applicationId }) =>
    svc.getAllUserRolesForApplication(applicationId),
  [at.GET_ALL_MY_RESOURCES]: (svc) => svc.getAllUserResources(),
  [at.GET_ALL_RESOURCES_OF_TYPE_FOR_ADMINISTRATOR]: (
    svc,
    { excludeOwnedResources }
  ) => svc.getAllUserResourcesAdministeredByIdentity(excludeOwnedResources),
  [at.DELETE_RESOURCE]: (svc, { itemType, id }) =>
    svc.deleteResource(itemType, id),
  [at.UPDATE_RESOURCE]: (svc, { itemType, id, body }) =>
    svc.updateResource(itemType, id, body),
  [at.CREATE_RESOURCE]: (svc, { itemType, body }) =>
    svc.createResource(itemType, body),
  [at.TRANSFER_RESOURCE_OWNER]: (svc, { itemType, id, ownerId }) =>
    svc.transferResourceOwner(itemType, id, ownerId),
  [at.GET_RESOURCE_BY_ID_AND_TYPE]: (svc, { itemType, id }) =>
    svc.getResource(itemType, id),
  [at.GET_ALL_MY_RESOURCES_OF_TYPE]: (svc, { itemType }) =>
    svc.getAllUserResources(itemType),
  [at.GET_ALL_RESOURCES_OF_RESOURCE_TYPE]: (svc, { resourceType }) =>
    svc.getAllResourcesOfResourceType(resourceType),
  [at.SET_MFA_SETTINGS_FOR_ACCOUNT]: (svc, { id, data }) =>
    svc.setMfaSettingsForAccount(id, data),
  [at.GET_MFA_SETTINGS_FOR_ACCOUNT]: (svc, { id }) =>
    svc.getMfaSettingsForAccount(id),
  [at.GET_ACCOUNTS]: (svc, { queryFilter }) => svc.getAccounts(queryFilter),
  [at.GET_MAIL_PROPERTIES]: (svc, { identityId }) =>
    svc.getMailProperties(identityId),
  [at.GET_IDENTITY_SUBSCRIPTIONS]: (svc, { identityId }) =>
    svc.getIdentitySubscriptions({ identityId }),
  [at.GET_CURRENT_IDENTITY_SUBSCRIPTIONS]: (svc) =>
    svc.getCurrentIdentitySubscriptions(),
  [at.SET_MAIL_PROPERTIES]: (svc, { identityId, newAlias }) =>
    svc.setMailProperties(identityId, newAlias),
  [at.SEARCH_MAIL_PROPERTIES_IDENTIFIER]: (svc, params) =>
    svc.searchMailPropertiesIdentifier(
      params.identifierName,
      params.identifierValues
    ),
  [at.UPDATE_MAIL_PROPERTIES]: (svc, { identityId, body }) =>
    svc.updateMailProperties(identityId, body),
  [at.GET_DYNAMIC_GROUP_CRITERIA]: (svc) => svc.getDynamicGroupCriteria(),
  [at.GET_MY_DYNAMIC_GROUP_CRITERIA]: (svc) => svc.getMyDynamicGroupCriteria(),
  [at.GET_ALLOWED_TOKEN_EXCHANGE_PERMISSIONS]: (
    svc,
    { registrationId, queryFilter }
  ) => svc.getTokenExchangePermissions(registrationId, queryFilter, "allowed"),
  [at.GET_GRANTED_TOKEN_EXCHANGE_PERMISSIONS]: (
    svc,
    { registrationId, queryFilter }
  ) => svc.getTokenExchangePermissions(registrationId, queryFilter, "granted"),
  [at.SEARCH_REGISTRATIONS]: (svc, { authenticationProviderId, queryFilter }) =>
    svc.searchRegistrations(authenticationProviderId, queryFilter),
  [at.PUT_TOKEN_EXCHANGE_REQUEST_WITH_BODY_PARAMS]: (svc, body) =>
    svc.createTokenExchangeRequestWithBodyParameters(body),
  [at.PUT_TOKEN_EXCHANGE_REQUEST]: (svc, { target, source }) =>
    svc.createTokenExchangeRequest(target, source),
  [at.DELETE_TOKEN_EXCHANGE_PERMISSION]: (svc, { target, source }) =>
    svc.deleteTokenExchangePermission(target, source),
  [at.GET_LEVELS_OF_ASSURANCE]: (svc) => svc.getLevelsOfAssurance(),
  [at.PATCH_IDENTITY]: (svc, { id, body }) => svc.patchIdentity(id, body),
  [at.GET_GROUP_SCOPES]: (svc, { queryFilter }) =>
    svc.getGroupScopes(queryFilter),
  [at.GET_GROUP_SCOPE_BY_ID]: (svc, { id }) => svc.getGroupScopeById(id),
  [at.GET_SCOPES_FOR_GROUP]: (svc, { id }) => svc.getScopesForGroup(id),
  [at.ADD_GROUP_TO_SCOPE]: (svc, { groupId, scopeId }) =>
    svc.addGroupToScope(groupId, scopeId),
  [at.REMOVE_GROUP_FROM_SCOPE]: (svc, { groupId, scopeId }) =>
    svc.removeGroupFromScope(groupId, scopeId),
  [at.GET_ACCOUNT_SCOPES]: (svc, { queryFilter }) =>
    svc.getAccountScopes(queryFilter),
  [at.GET_ACCOUNT_SCOPE_BY_ID]: (svc, { id }) => svc.getAccountScopeById(id),
  [at.GET_SCOPES_FOR_ACCOUNT]: (svc, { id }) => svc.getScopesForAccount(id),
  [at.ADD_ACCOUNT_TO_SCOPE]: (svc, { accountId, scopeId }) =>
    svc.addAccountToScope(accountId, scopeId),
  [at.REMOVE_ACCOUNT_FROM_SCOPE]: (svc, { accountId, scopeId }) =>
    svc.removeAccountFromScope(accountId, scopeId),
  [at.UPDATE_EXTERNAL_EMAIL]: (svc, { id, email }) =>
    svc.updateExternalEmail(id, email),
  [at.ASSOCIATE_SOCIAL_IDENTITY]: (svc, { token }) =>
    svc.associateSocialIdentity(token),
  [at.GET_GROUP_ROLE_ASSIGNMENTS]: (svc, { groupId }) =>
    svc.getGroupRoleAssignments(groupId),
  [at.GET_OWNED_IDENTITIES]: (svc, { ownerId }) =>
    svc.getOwnedIdentities(ownerId),
  [at.RESET_PASSWORD]: (svc, { accountId, newPassword }) =>
    svc.resetPassword(accountId, newPassword),
  [at.SET_INITIAL_PASSWORD]: (svc, { accountId, newPassword }) =>
    svc.setInitialPassword(accountId, newPassword),
  [at.SERVICE_DESK_RESET_PASSWORD]: (svc, { accountId }) =>
    svc.serviceDeskResetPassword(accountId),
  [at.SERVICE_DESK_RESET_PASSWORD_EMAIL]: (
    svc,
    { accountId, isEdhAccount, email, newPassword }
  ) =>
    svc.serviceDeskResetPasswordEmail(
      accountId,
      isEdhAccount,
      email,
      newPassword
    ),
  [at.CHANGE_PASSWORD]: (svc, { accountId, oldPassword, newPassword }) =>
    svc.changePassword(accountId, oldPassword, newPassword),
  [at.CHANGE_EDHPASSWORD]: (svc, { accountId, oldPassword, newPassword }) =>
    svc.changeEdhPassword(accountId, oldPassword, newPassword),
  [at.SERVICE_DESK_RESET_EDHPASSWORD]: (svc, { accountId }) =>
    svc.serviceDeskResetEdhPassword(accountId),
  [at.GET_EDHPASSWORD_HISTORY]: (svc, { accountId }) =>
    svc.getEdhPasswordHistory(accountId),
  [at.SEARCH_AUDIT_LOGS]: (svc, { queryFilter }) =>
    svc.searchAuditLogs(queryFilter),
  [at.CREATE_SERVICE]: (svc, { ownerId, login }) =>
    svc.createServiceLogin(ownerId, login),
  [at.BLOCK_IDENTITY]: (svc, { identityId, blocked, reason, securityIssues }) =>
    svc.blockIdentity(identityId, blocked, reason, securityIssues),
  [at.CREATE_SECONDARY]: (svc, { ownerId, login }) =>
    svc.createSecondaryLogin(ownerId, login),
  [at.GET_ACCOUNTS_FOR_IDENTITY]: (svc, { identityId }) =>
    svc.getAccountsForIdentity(identityId),
  [at.TRANSFER_IDENTITY]: (svc, { identityId, otherIdentityId }) =>
    svc.transferIdentity(identityId, otherIdentityId),
  [at.GET_PROTOCOL_SCOPES]: (svc) => svc.getProtocolScopes(),
  [at.CHECK_POLICY_SCRIPT]: (svc, { applicationId, identityId, request }) =>
    svc.checkPolicyScript(applicationId, identityId, request),
  [at.GET_IS_MEMBER_RECURSIVE]: (svc, { upn, groupIdentifier }) =>
    svc.getIsMemberRecursive(upn, groupIdentifier),
  [at.CREATE_MEMBERSHIP_RESTRICTION]: (
    svc,
    { enforcedGroupId, sourceGroupId }
  ) => svc.createMembershipRestriction(enforcedGroupId, sourceGroupId),
  [at.DELETE_MEMBERSHIP_RESTRICTION]: (
    svc,
    { enforcedGroupId, sourceGroupId }
  ) => svc.deleteMembershipRestriction(enforcedGroupId, sourceGroupId),
  [at.GET_MEMBERSHIP_RESTRICTION]: (svc, { enforcedGroupId }) =>
    svc.getMembershipRestrictionForGroup(enforcedGroupId),
  [at.DELETE_IDENTITY_MEMBERSHIP_FROM_GROUP]: (svc, { groupId, identityId }) =>
    svc.deleteIdentityMembershipFromGroup(groupId, identityId),
  [at.ACTIVATE_OR_BLOCK_ACCOUNT]: (svc, { idOrUpn, blocked, reason }) =>
    svc.activateOrBlockAccount(idOrUpn, blocked, reason),
  [at.ACTIVATE_OR_BLOCK_IDENTITY]: (svc, { idOrUpn, blocked, reason }) =>
    svc.activateOrBlockIdentity(idOrUpn, blocked, reason),
  [at.GET_ALL_GROUP_MAIL_PROPERTIES]: (svc, { queryParams }) =>
    svc.getAllGroupMailProperties(queryParams),
  [at.GET_GROUP_MAIL_PROPERTIES]: (svc, { id }) =>
    svc.getGroupMailProperties(id),
  [at.UPDATE_GROUP_MAIL_PROPERTIES]: (svc, { id, body }) =>
    svc.updateGroupMailProperties(id, body),
  [at.PATCH_GROUP_MAIL_PROPERTIES]: (svc, { id, body }) =>
    svc.patchGroupMailProperties(id, body),
  [at.GET_GROUP_MAIL_PROPERTIES_FOR_GROUP]: (svc, { groupId }) =>
    svc.getGroupMailPropertiesForGroup(groupId),
  [at.UPDATE_GROUP_MAIL_PROPERTIES_FOR_GROUP]: (svc, { groupId, body }) =>
    svc.updateGroupMailPropertiesForGroup(groupId, body),
  [at.ADD_GROUP_MAIL_PROPERTIES_IDENTITIES_ALLOWED_TO_POST]: (
    svc,
    { groupId, identityIds }
  ) => svc.addGroupMailPropertiesIdentitiesAllowedToPost(groupId, identityIds),
  [at.DELETE_GROUP_MAIL_PROPERTIES_IDENTITIES_ALLOWED_TO_POST]: (
    svc,
    { groupId, identityIds }
  ) =>
    svc.deleteGroupMailPropertiesIdentitiesAllowedToPost(groupId, identityIds),
  [at.ADD_GROUP_MAIL_PROPERTIES_GROUPS_ALLOWED_TO_POST]: (
    svc,
    { groupId, groupIdentifiers }
  ) => svc.addGroupMailPropertiesGroupsAllowedToPost(groupId, groupIdentifiers),
  [at.DELETE_GROUP_MAIL_PROPERTIES_GROUPS_ALLOWED_TO_POST]: (
    svc,
    { groupId, groupIds }
  ) => svc.deleteGroupMailPropertiesGroupsAllowedToPost(groupId, groupIds),
  [at.GET_GROUP_MEMBERS_CSV]: (svc, { groupId, compatibilityMode }) =>
    svc.getGroupMembersCSV(groupId, compatibilityMode),
  [at.PUT_GROUP_MEMBER_CSV]: (
    svc,
    { groupId, compatibilityMode, replace, file }
  ) => svc.putGroupMembersCSV(groupId, compatibilityMode, replace, file),
});

/**
 * API calls for which the standard json loading shouldn't be done (such as search)
 */
export const noJsonLoading = [at.SEARCH_IDENTITY];
