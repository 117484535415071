import { IdentitiesSearchProperties } from "../../../typings/DefaultSearchProperties";
import { mergeEntities } from "../actions";
import { HttpService } from "./HttpService";
import {
  ApiError,
  ApiVersions,
  extractEmailFromQuery,
  isEmail,
  renderFormParams,
  replaceApiVersion,
} from "./serviceUtil";

class AuthService extends HttpService {
  constructor(keycloak, cfg) {
    super(keycloak, cfg);

    this.serverUrl = cfg.baseAuthorizationServerUrl;
    this.audience = cfg.authAudience;
    this.tokenName = "AuthService";

    this.accountUrl = `${this.serverUrl}/Account`;
    this.identityUrl = `${this.serverUrl}/Identity`;
    this.auditUrl = `${this.serverUrl}/Audit`;
    this.userRolesUrl = `${this.serverUrl}/UserRole`;
    this.myIdentityUrl = `${this.identityUrl}/my`;
    this.myIdentityGroupsUrl = `${this.myIdentityUrl}/groups`;
    this.allMyUserRolesForAppUrl = `${this.userRolesUrl}/application/identifier`;
    this.ownedResourcesUrl = `${this.serverUrl}/OwnedResource`;
    this.resourcesUrl = `${this.serverUrl}`;
    this.accountManagementUrl = `${this.serverUrl}/AccountManagement`;
    this.serviceDeskUrl = `${this.serverUrl}/ServiceDesk`;
    this.passwordsUrl = `${this.serverUrl}/Passwords`;

    this.applicationUrl = `${this.serverUrl}/Application`;
    this.applicationV2Url = `${replaceApiVersion(
      this.serverUrl,
      ApiVersions.V2
    )}/Application`;

    this.registrationUrl = `${this.serverUrl}/Registration`;

    this.requestsUrl = `${this.serverUrl}/Request`;
    this.myRequestsUrl = `${this.requestsUrl}/my`;
    this.requestsToApproveUrl = `${this.requestsUrl}/to_approve`;

    this.groupsUrl = `${this.serverUrl}/Group`;
    this.policyCheckUrl = `${this.serverUrl}/PolicyCheck`;
    this.groupScopeUrl = `${this.serverUrl}/GroupScope`;

    this.accountScopeUrl = `${this.serverUrl}/AccountScope`;

    this.levelOfAssuranceUrl = `${this.serverUrl}/LevelOfAssurance`;

    this.mailPropertiesUrl = `${this.serverUrl}/MailProperties`;

    this.groupMailPropertiesUrl = `${this.serverUrl}/GroupMailProperties`;
  }

  // Gets the groups to which the current user belongs to
  async getMyGroups(queryFilter) {
    return this.get(this.myIdentityGroupsUrl, queryFilter);
  }

  /**
   * Get all the group scopes, with whatever filtering criteria were passed
   * @param {object} queryFilter should be of the type {"limit": ..., "filter": ..., "field", "sort"}
   */
  async getGroupScopes(queryFilter) {
    return this.get(this.groupScopeUrl, queryFilter);
  }

  async getOwnedIdentities(ownerId) {
    const formParams = renderFormParams({
      filter: [`ownerId:${ownerId}`],
      sort: [`upn:asc`],
    });
    return this.sendRequest(`${this.identityUrl}?${formParams}`, "GET");
  }

  /**
   * Service desk call to block identities
   * @param {string} identityId the identity ID
   * @param {boolean} blocked whether to block or unblock the identity.
   * @param {string} reason what reason we have for blocking
   * @param {boolean} securityIssues whether this blocking request is done for security issues or not
   */
  async blockIdentity(identityId, blocked, reason, securityIssues) {
    return this.put(`${this.serviceDeskUrl}/${identityId}/block`, {
      blocked,
      reason,
      securityIssues,
    });
  }

  async resetPassword(accountId, newPassword) {
    return this.sendRequest(`${this.passwordsUrl}/${accountId}/reset`, "POST", {
      newPassword,
    });
  }

  async setInitialPassword(accountId, newPassword) {
    return this.sendRequest(`${this.passwordsUrl}/${accountId}/set`, "POST", {
      newPassword,
    });
  }

  async serviceDeskResetPassword(accountId) {
    return this.sendRequest(
      `${this.passwordsUrl}/${accountId}/sd/reset`,
      "POST"
    );
  }

  async serviceDeskResetPasswordEmail(
    accountId,
    isEdhAccount,
    email,
    newPassword
  ) {
    return this.sendRequest(
      `${this.passwordsUrl}/${accountId}/sd/reset/email`,
      "POST",
      { isEdhAccount, email, newPassword }
    );
  }

  async changePassword(accountId, oldPassword, newPassword) {
    return this.sendRequest(
      `${this.passwordsUrl}/${accountId}/change`,
      "POST",
      { newPassword, oldPassword }
    );
  }

  async serviceDeskResetEdhPassword(accountId) {
    return this.sendRequest(
      `${this.passwordsUrl}/edhpassword/${accountId}/sd/reset`,
      "POST"
    );
  }

  async changeEdhPassword(accountId, oldPassword, newPassword) {
    return this.sendRequest(
      `${this.passwordsUrl}/edhpassword/${accountId}/change`,
      "POST",
      { newPassword, oldPassword }
    );
  }

  async getEdhPasswordHistory(accountId) {
    return this.sendRequest(
      `${this.passwordsUrl}/edhpassword/${accountId}/history`,
      "GET"
    );
  }

  async createServiceLogin(ownerId, login) {
    return this.sendRequest(
      `${this.accountManagementUrl}/${ownerId}/service`,
      "POST",
      login
    );
  }

  async createSecondaryLogin(ownerId, login) {
    return this.sendRequest(
      `${this.accountManagementUrl}/${ownerId}/secondary`,
      "POST",
      login
    );
  }

  /**
   * Get a group scope by ID
   * @param {object} scopeId the scope ID
   */
  async getGroupScopeById(scopeId) {
    return this.get(`${this.groupScopeUrl}/${encodeURIComponent(scopeId)}`);
  }

  /**
   * Add a group to a scope
   * @param {object} scopeId the scope ID
   */
  async addGroupToScope(groupId, scopeId) {
    return this.sendRequest(
      `${this.groupsUrl}/${encodeURIComponent(
        groupId
      )}/scopes/${encodeURIComponent(scopeId)}`,
      "POST"
    );
  }

  /**
   * Check the policy script for the application and identity ID passed
   * @param {string} applicationId the application ID
   * @param {string} identityId the identity ID
   * @param {object} request the request containing the script
   */
  async checkPolicyScript(applicationId, identityId, request) {
    return this.sendRequest(
      `${this.policyCheckUrl}/${encodeURIComponent(
        applicationId
      )}/${encodeURIComponent(identityId)}`,
      "POST",
      request
    );
  }

  /**
   * Remove a group from a scope
   * @param {object} scopeId the scope ID
   */
  async removeGroupFromScope(groupId, scopeId) {
    return this.sendRequest(
      `${this.groupsUrl}/${encodeURIComponent(
        groupId
      )}/scopes/${encodeURIComponent(scopeId)}`,
      "DELETE"
    );
  }

  /**
   * Get all the scopes for a group
   * @param {object} scopeId the scope ID
   */
  async getScopesForGroup(groupId) {
    return this.sendRequest(
      `${this.groupsUrl}/${encodeURIComponent(groupId)}/scopes`,
      "GET"
    );
  }

  /**
   * Get all the scopes for an account
   * @param {object} scopeId the scope ID
   */
  async getScopesForAccount(accountId) {
    return this.sendRequest(
      `${this.accountUrl}/${encodeURIComponent(accountId)}/scopes`,
      "GET"
    );
  }

  /**
   * Get all the account scopes, with whatever filtering criteria were passed
   * @param {object} queryFilter should be of the type {"limit": ..., "filter": ..., "field", "sort"}
   */
  async getAccountScopes(queryFilter) {
    return this.get(this.accountScopeUrl, queryFilter);
  }

  /**
   * Get an account scope by ID
   * @param {object} scopeId the scope ID
   */
  async getAccountScopeById(scopeId) {
    return this.get(`${this.accountScopeUrl}/${encodeURIComponent(scopeId)}`);
  }

  /**
   * Add an account to a scope
   * @param {object} scopeId the scope ID
   */
  async addAccountToScope(accountId, scopeId) {
    return this.sendRequest(
      `${this.accountUrl}/${encodeURIComponent(
        accountId
      )}/scopes/${encodeURIComponent(scopeId)}`,
      "POST"
    );
  }

  /**
   * Remove an account from a scope
   * @param {object} scopeId the scope ID
   */
  async removeAccountFromScope(accountId, scopeId) {
    return this.sendRequest(
      `${this.accountUrl}/${encodeURIComponent(
        accountId
      )}/scopes/${encodeURIComponent(scopeId)}`,
      "DELETE"
    );
  }

  async getRoleByName(roleName, applicationId) {
    return this.get(`${this.userRolesUrl}/${roleName}/${applicationId}`, null);
  }

  async getGroupsAssignedToRole(roleId) {
    return this.get(`${this.userRolesUrl}/${roleId}/groups`, null);
  }

  async getAllUserRolesForApplication(applicationId) {
    return this.sendRequest(
      `${this.allMyUserRolesForAppUrl}/${applicationId}`,
      "GET"
    );
  }

  async getAllUserResources(itemType) {
    if (itemType) {
      return this.sendRequest(`${this.resourcesUrl}/${itemType}/my`, "GET");
    }
    return this.sendRequest(this.ownedResourcesUrl, "GET");
  }

  async getAllResourcesOfResourceType(resourceType) {
    return this.sendRequest(`${this.resourcesUrl}/${resourceType}`, "GET");
  }

  async getAllUserResourcesAdministeredByIdentity(excludeOwnedResources) {
    if (excludeOwnedResources) {
      return this.sendRequest(
        `${this.ownedResourcesUrl}/asAdministrator?excludeOwnedResources=True`,
        "GET"
      );
    }
    return this.sendRequest(`${this.ownedResourcesUrl}/asAdministrator`, "GET");
  }

  async deleteResource(itemType, id) {
    return this.sendRequest(`${this.resourcesUrl}/${itemType}/${id}`, "DELETE");
  }

  async getResource(itemType, id) {
    return this.sendRequest(`${this.resourcesUrl}/${itemType}/${id}`, "GET");
  }

  async updateResource(itemType, id, body) {
    return this.sendRequest(
      `${this.resourcesUrl}/${itemType}/${id}`,
      "PUT",
      body
    );
  }

  async createResource(itemType, body) {
    return this.sendRequest(`${this.resourcesUrl}/${itemType}`, "POST", body);
  }

  async transferResourceOwner(itemType, resourceId, identityId) {
    return this.sendRequest(
      `${this.resourcesUrl}/${itemType}/${resourceId}/transfer/${identityId}`,
      "PUT"
    );
  }

  // Gets the current user's identity
  async getMyIdentity(queryParams) {
    return this.get(this.myIdentityUrl, queryParams);
  }

  async createIdentity(identity) {
    return this.sendRequest(this.identityUrl, "POST", identity);
  }

  /**
   * Checks if the identity identifier(s) are already taken or not
   * @param {string} identifierName The property to lookup, e.g. `upn`
   * @param {array} identifierValues Values to search
   */
  async searchIdentityIdentifier(identifierName, identifierValues) {
    return this.sendRequest(
      `${this.identityUrl}/identifierAvailability`,
      "POST",
      {
        identifierName,
        identifierValues,
      }
    );
  }

  async updateIdentity(identity) {
    return this.put(`${this.identityUrl}/${identity.id}`, identity);
  }

  async setAppStatus(appId, payload) {
    return this.put(`${this.applicationUrl}/${appId}/status`, payload);
  }

  async deleteIdentity(identityid) {
    return this.sendRequest(`${this.identityUrl}/${identityid}`, "DELETE");
  }

  async resolveIdentity(identityid) {
    return this.sendRequest(`${this.identityUrl}/${identityid}`, "GET");
  }

  async resolveAuditsForEntity(id, queryFilter) {
    let actualQueryFilter = {};
    if (!queryFilter) {
      actualQueryFilter = { sort: "time:desc" };
    } else if (queryFilter.sort === undefined) {
      actualQueryFilter = { ...queryFilter, sort: "time:desc" };
    }
    return this.get(`${this.auditUrl}/entity/${id}`, actualQueryFilter);
  }

  /**
   * Get detailed information for the audit operation
   */
  async getAuditOperationDetails(id) {
    return this.get(`${this.auditUrl}/${id}/details`, null);
  }

  /**
   * Get an audit operation by ID.
   */
  async getAuditOperationById(id) {
    return this.get(`${this.auditUrl}/${id}`, null);
  }

  async getGroupById(id, fields = []) {
    let queryParams = "";
    if (fields && fields.length > 0) {
      queryParams = "?" + renderFormParams({ field: fields });
    }
    return this.sendRequest(`${this.groupsUrl}/${id}${queryParams}`, "GET");
  }

  async getCurrentIdentityGroups(queryParams) {
    return this.get(`${this.identityUrl}/current/groups`, queryParams);
  }

  async subscribeCurrentIdentityToGroups(body) {
    return this.sendRequest(`${this.identityUrl}/current/groups`, "POST", body);
  }

  async unsubscribeCurrentIdentityFromGroups(body) {
    return this.sendRequest(
      `${this.identityUrl}/current/groups`,
      "DELETE",
      body
    );
  }

  async getCurrentIdentityGroupsRecursively(queryParams) {
    return this.get(
      `${this.identityUrl}/current/groups/recursive`,
      queryParams
    );
  }

  async getCurrentIdentityAccounts() {
    return this.sendRequest(`${this.identityUrl}/current/accounts`, "GET");
  }

  async getAccountsForIdentity(identityId) {
    return this.sendRequest(
      `${this.identityUrl}/${identityId}/accounts`,
      "GET"
    );
  }

  async getAccounts(queryFilter) {
    return this.get(this.accountUrl, queryFilter);
  }

  async getCurrentIdentityPrimaryAccount() {
    return this.get(`${this.accountUrl}/primary`);
  }

  async getAccountProviders(queryParams) {
    return this.get(`${this.accountUrl}/providers`, queryParams);
  }

  async getProtocolScopes() {
    return this.get(`${this.registrationUrl}/scopes`);
  }

  async getMfaSettingsForAccount(id) {
    return this.get(`${this.accountUrl}/${id}/mfa`, null);
  }

  async setMfaSettingsForAccount(id, data) {
    return this.put(`${this.accountUrl}/${id}/mfa`, data);
  }

  async setPrimaryAccount(accountId) {
    return this.put(`${this.accountUrl}/primary/${accountId}`);
  }

  async getAccountById(id) {
    return this.sendRequest(
      `${this.identityUrl}/current/accounts/${id}`,
      "GET"
    );
  }

  async getRequestsToApprove(queryParams) {
    return this.get(`${this.requestsToApproveUrl}`, queryParams);
  }

  async getRequests(queryParams) {
    return this.get(`${this.myRequestsUrl}`, queryParams);
  }

  async cancelRequest(id) {
    return this.sendRequest(`${this.requestsUrl}/${id}/cancel`, "POST");
  }

  async denyRequest(id, reason) {
    const paramsString = renderFormParams({ reason: reason });
    return this.sendRequest(
      `${this.requestsUrl}/${id}/deny?${paramsString}`,
      "POST"
    );
  }

  async approveRequest(id, reason, approvalToken) {
    const paramsString = renderFormParams({
      reason: reason,
      approvalToken: approvalToken,
    });
    return this.sendRequest(
      `${this.requestsUrl}/${id}/approve?${paramsString}`,
      "POST"
    );
  }

  async searchByEmail(email, identityType) {
    const formParams = renderFormParams({
      limit: 10,
    });

    return this.sendRequest(
      `${this.identityUrl}/by_email/${encodeURIComponent(email)}?${formParams}`,
      "GET"
    );
  }

  /**
   * Search all identities by several fields given by the entered string value.
   * Current used field for search are defined in {@link typing/DefaultSearchProperties.js} file
   * @param {String} value containing the value to be searched among all fields
   * @param {String} identityType type of the identity to be searched (optional parameter, set as undefined to look
   * among all identities). Available types: [ Undefined, Person, Application, Service, Secondary ]
   * @returns {Promise<[]>}
   */
  async searchIdentity(value, identityType) {
    try {
      let requests = [];
      IdentitiesSearchProperties.filterFields.forEach((field) => {
        // Default filter with field and value
        let filters = [`${field}:Contains:${value}`];
        // Add identity filter if specified by query
        identityType && filters.push(`type:${identityType}`);

        const formParams = renderFormParams({
          limit: 10,
          filter: filters,
        });

        requests.push(
          this.sendRequest(`${this.identityUrl}?${formParams}`, "GET")
        );
      });

      // Perform search by email
      if (isEmail(String(value), false)) {
        requests.push(this.searchByEmail(value));
      }

      // Await for results of all promises
      const responses = await Promise.all(requests);
      const results = await Promise.all(responses.map((r) => r.json()));

      return mergeEntities(results);
    } catch (e) {
      return Promise.reject(e);
    }
  }

  /**
   * Search all identities by several fields given by the queryFilter parameter
   * @param {Object} queryFilter object containing field to be returned and search criteria
   *  @param {Array.<string>} queryFilter.field string array of the fields to retrieve
   *  @param {Array.<string>} queryFilter.filter string array of search criteria. The strings inside the array have
   *  the format fieldToSearch:positionCriteria:value, such as 'upn:StartsWith:someValue'
   * @returns {Promise | {data: Array.<string>}} Returns a promise if some error happened in the process. Otherwise a
   * JS Object with a field 'data' containing all the data retrieved
   */
  async searchAllIdentities(queryFilter) {
    let email = extractEmailFromQuery(queryFilter);

    try {
      let requests = [];
      if (email) {
        requests.push(this.searchByEmail(email));
      }
      requests.push(this.get(this.identityUrl, queryFilter));

      // Await all promises to be finished
      const responses = await Promise.all(requests);
      // Resolve all the promises
      const results = await Promise.all(responses.map((r) => r.json()));

      const merged_results = mergeEntities(results);
      return { data: merged_results };
    } catch (e) {
      return Promise.reject(e);
    }
  }

  /**
   * Queries for all the applications in the API
   * @param {*} queryFilter the query for filtering, limiting, pagination, etc
   */
  async searchAuditLogs(queryFilter) {
    let actualQueryFilter = {};
    if (!queryFilter) {
      actualQueryFilter = { sort: "time:desc" };
    } else if (queryFilter.sort === undefined) {
      actualQueryFilter = { ...queryFilter, sort: "time:desc" };
    }
    return this.get(this.auditUrl, actualQueryFilter);
  }

  async associateIdentity(otherId) {
    return this.sendRequest(
      `${this.identityUrl}/current/associate/${otherId}`,
      "PUT"
    );
  }

  async serviceDeskAssociateIdentity(targetId, otherId) {
    return this.sendRequest(
      `${this.identityUrl}/sd/${targetId}/associate/${otherId}`,
      "PUT"
    );
  }

  async serviceDeskRenameIdentity(identityId, newName) {
    const paramsString = renderFormParams({ newName });
    return this.sendRequest(
      `${this.identityUrl}/sd/rename/${identityId}?${paramsString}`,
      "PUT"
    );
  }

  async serviceDeskExtendAccountExpiration(newAccount) {
    return this.sendRequest(
      `${this.accountUrl}/sd/${newAccount.id}/extend`,
      "PUT",
      newAccount
    );
  }

  async associateSocialIdentity(token) {
    return this.sendRequest(
      `${this.identityUrl}/current/associate/social/${token}`,
      "PUT"
    );
  }

  async deleteAccountFromIdentity(accountid) {
    return this.sendRequest(
      `${this.identityUrl}/current/accounts/${accountid}`,
      "DELETE"
    );
  }

  //Application APIs

  /**
   * Queries for all the applications in the API
   * @param {*} queryFilter the query for filtering, limiting, pagination, etc
   */
  async getAllApplications(queryFilter) {
    return this.get(this.applicationUrl, queryFilter);
  }

  async getGroupsForIdentity(identityId, queryFilter) {
    return this.get(`${this.identityUrl}/${identityId}/groups`, queryFilter);
  }

  async getIsMemberRecursive(upn, groupIdentifier) {
    return this.get(
      `${this.identityUrl}/${upn}/isMemberRecursive/${groupIdentifier}`
    );
  }

  async getApplicationList(queryFilter) {
    return this.get(`${this.applicationUrl}/my`, queryFilter);
  }

  async getApplication(applicationid) {
    return this.sendRequest(`${this.applicationUrl}/${applicationid}`, "GET");
  }

  async getApplicationLifecycleSettings(applicationid) {
    return this.sendRequest(
      `${this.applicationUrl}/${applicationid}/lifecycleSettings`,
      "GET"
    );
  }

  async getApplicationByManagedResourceType(managedResourceType) {
    return this.sendRequest(
      `${this.resourcesUrl}/${managedResourceType}/application`,
      "GET"
    );
  }

  async deleteApplication(applicationid) {
    const paramsString = renderFormParams({ applicationid: applicationid });
    return this.sendRequest(
      `${this.applicationUrl}/${applicationid}?${paramsString}`,
      "DELETE"
    );
  }

  async addApplication(application) {
    return this.sendRequest(`${this.applicationUrl}`, "POST", application);
  }

  async editApplication(application) {
    return this.sendRequest(
      `${this.applicationUrl}/${application.id}`,
      "PUT",
      application
    );
  }

  async addRole(role) {
    const paramsString = renderFormParams(role);
    return this.sendRequest(
      `${this.applicationUrl}/${role.applicationId}/roles?${paramsString}`,
      "POST",
      role
    );
  }

  async deleteRole(applicationid, roleid) {
    return this.sendRequest(
      `${this.applicationUrl}/${applicationid}/roles/${roleid}`,
      "DELETE"
    );
  }

  /**
   * Update a role for an application
   * @param {*} applicationId
   * @param {*} roleId
   * @param {*} role
   */
  async updateRole(applicationId, roleId, role) {
    return this.sendRequest(
      `${this.applicationUrl}/${applicationId}/roles/${roleId}`,
      "PUT",
      role
    );
  }

  async getRoles(applicationid) {
    return this.sendRequest(
      `${this.applicationUrl}/${applicationid}/roles`,
      "GET"
    );
  }

  async getRoleForApplication(applicationid, roleid) {
    return this.sendRequest(
      `${this.applicationUrl}/${applicationid}/roles/${roleid}`,
      "GET"
    );
  }

  async getGroupsForRole(applicationid, roleid) {
    return this.sendRequest(
      `${this.applicationUrl}/${applicationid}/roles/${roleid}/groups`,
      "GET"
    );
  }

  async deleteGroupFromRole(applicationid, roleid, groupid) {
    return this.sendRequest(
      `${this.applicationUrl}/${applicationid}/roles/${roleid}/groups/${groupid}`,
      "DELETE"
    );
  }

  async addGroupToRole(applicationid, roleid, groupid) {
    return this.sendRequest(
      `${this.applicationUrl}/${applicationid}/roles/${roleid}/groups/${groupid}`,
      "POST"
    );
  }

  // Application V2 APIs
  async getAllApplicationsV2(queryFilter) {
    return this.get(this.applicationV2Url, queryFilter);
  }

  async getApplicationListV2(queryFilter) {
    return this.get(`${this.applicationV2Url}/my`, queryFilter);
  }

  // Registration APIs
  async getRegistrationsForApplication(applicationid) {
    return this.sendRequest(`${this.registrationUrl}/${applicationid}`, "GET");
  }

  async getProviders(applicationid) {
    return this.sendRequest(`${this.registrationUrl}/providers`, "GET");
  }

  async registerApplication(applicationid, providerid, registrationDetails) {
    return this.sendRequest(
      `${this.registrationUrl}/${applicationid}/${providerid}`,
      "POST",
      registrationDetails
    );
  }

  async updateRegistration(providerid, registrationDetails) {
    return this.sendRequest(
      `${this.registrationUrl}/${providerid}`,
      "PUT",
      registrationDetails
    );
  }

  async deleteRegistration(registrationid) {
    return this.sendRequest(
      `${this.registrationUrl}/${registrationid}`,
      "DELETE"
    );
  }

  async displaySecret(registrationid) {
    return this.sendRequest(
      `${this.registrationUrl}/${registrationid}/secret`,
      "GET"
    );
  }

  async regenerateSecret(registrationid, providerid) {
    return this.sendRequest(
      `${this.registrationUrl}/${registrationid}/${providerid}/invoke/client-secret`,
      "POST",
      {}
    );
  }

  /**
   * Search for a group given a filter
   * @param {string} queryFilter a list or single item containing the query to search by
   * @param {number} limit how many results to show
   */
  async searchGroup(queryFilter) {
    return this.get(`${this.groupsUrl}`, queryFilter);
  }

  /**
   * Gets an identity by the ID passed as a parameter.
   * @param {string} id
   */
  async getIdentityById(id, queryFilter) {
    return this.get(`${this.identityUrl}/${id}`, queryFilter);
  }

  /**
   * Gets the memberships of an identity (aka groups they belong to)
   */
  async getIdentityMemberships(identityId, queryFilter) {
    return this.get(`${this.identityUrl}/${identityId}/groups`, queryFilter);
  }

  /**
   * Get the roles assigned to this group, grouped by application
   * @param {string} groupId the group ID
   */
  async getGroupRoleAssignments(groupId) {
    return this.sendRequest(`${this.groupsUrl}/${groupId}/roles`, "GET");
  }

  /**
   * Create a static group
   * @param groupData the payload for creating a group
   */
  async createStaticGroup(groupData) {
    return this.sendRequest(this.groupsUrl, "POST", groupData);
  }

  /**
   * Checks if the group identifier(s) are already taken or not
   * @param {string} identifierName The property to lookup, e.g. `groupIdentifier`
   * @param {array} identifierValues Values to search
   */
  async searchGroupIdentifier(identifierName, identifierValues) {
    return this.sendRequest(
      `${this.groupsUrl}/identifierAvailability`,
      "POST",
      {
        identifierName,
        identifierValues,
      }
    );
  }

  /**
   * Updates a group with the given parameters
   * @param {string} groupId  the ID of the group to update
   * @param {object} groupData the fields that need to be updated in the group
   */
  async updateGroup(groupId, groupData) {
    return this.sendRequest(`${this.groupsUrl}/${groupId}`, "PUT", groupData);
  }

  /**
   * Gets the groups where the current identity is an owner or belongs to the administrators
   */
  async getGroupsIOwn(queryFilter) {
    return this.get(`${this.groupsUrl}/my`, queryFilter);
  }

  /**
   * Gets all the member identities for this group
   * @param {string} groupId
   * @param {object} queryFilter
   */
  async getGroupMemberIdentities(groupId, queryFilter) {
    return this.get(
      `${this.groupsUrl}/${groupId}/members/identities`,
      queryFilter
    );
  }

  /**
   * Gets all the member identities for this group with memberships associated comment
   * @param {string} groupId
   * @param {object} queryFilter
   */
  async getGroupMemberIdentitiesWithComments(groupId, queryFilter) {
    return this.get(
      `${this.groupsUrl}/${groupId}/members/identitiesWithComments`,
      queryFilter
    );
  }

  /**
   * Given a target group id and the id of an identity member of the target group,
   * updates the comment linked to this membership
   * @param {*} groupId
   * @param {*} identityMemberId
   * @param {*} comment
   * @returns
   */
  async updateGroupMemberIdentityComment(groupId, identityMemberId, comment) {
    const queryParams = renderFormParams({ comment: comment });
    return this.sendRequest(
      `${this.groupsUrl}/${groupId}/members/identities/${identityMemberId}?${queryParams}`,
      "PUT"
    );
  }

  /**
   * Get the groups that are member of this group
   * @param {string} groupId
   * @param {object} queryFilter the query params to use
   */
  async getGroupMemberGroups(groupId, queryFilter) {
    return this.get(`${this.groupsUrl}/${groupId}/members/groups`, queryFilter);
  }

  /**
   * Get the groups that are member of this group and the memberships
   * associated comment
   * @param {string} groupId
   * @param {object} queryFilter the query params to use
   */
  async getGroupMemberGroupsWithComments(groupId, queryFilter) {
    return this.get(
      `${this.groupsUrl}/${groupId}/members/groupsWithComments`,
      queryFilter
    );
  }

  /**
   * Given a target group id and the id of a group member of the target group,
   * updates the comment linked to this membership
   * @param {*} groupId
   * @param {*} groupMemberId
   * @param {*} comment
   * @returns
   */
  async updateGroupMemberGroupComment(groupId, groupMemberId, comment) {
    const queryParams = renderFormParams({ comment: comment });
    return this.sendRequest(
      `${this.groupsUrl}/${groupId}/members/groups/${groupMemberId}?${queryParams}`,
      "PUT"
    );
  }

  /**
   * Transfer the group ownership of this group to another identity
   * @param {string} groupId
   * @param {string} identityId
   */
  async transferGroupOwnership(groupId, identityId) {
    return this.sendRequest(
      `${this.groupsUrl}/${groupId}/transfer/${identityId}`,
      "PUT"
    );
  }

  /**
   * Transfer the ownership of this application to another identity
   * @param {*} applicationId
   * @param {*} identityId
   */
  async transferApplicationOwnership(applicationId, identityId) {
    return this.sendRequest(
      `${this.applicationUrl}/${applicationId}/transfer/${identityId}`,
      "PUT"
    );
  }

  /**
   * Deletes the group with the passed ID
   * @param {string} groupId
   */
  async deleteGroupById(groupId) {
    return this.sendRequest(`${this.groupsUrl}/${groupId}`, "DELETE");
  }

  /**
   * Add a group as a member of this group
   * @param {string} groupId
   * @param {Array.<string>} memberGroupIds
   */
  async addGroupMemberGroups(groupId, memberGroupIds) {
    return this.sendRequest(
      `${this.groupsUrl}/${groupId}/members/groups`,
      "POST",
      memberGroupIds
    );
  }

  /**
   * Add an identity as a member of this group
   * @param {string} groupId
   * @param {Array.<string>} identityIds
   */
  async addGroupMemberIdentities(groupId, identityIds) {
    return this.sendRequest(
      `${this.groupsUrl}/${groupId}/members/identities`,
      "POST",
      identityIds
    );
  }

  /**
   * Delete a group as a member of this group
   * @param {string} groupId
   * @param {Array.<string>} memberGroupIds
   */
  async deleteGroupMemberGroups(groupId, memberGroupIds) {
    return this.sendRequest(
      `${this.groupsUrl}/${groupId}/members/groups`,
      "DELETE",
      memberGroupIds
    );
  }

  /**
   * Delete an identity as a member of this group
   * @param {string} groupId
   * @param {Array.<string>} identityIds
   */
  async deleteGroupMemberIdentities(groupId, identityIds) {
    return this.sendRequest(
      `${this.groupsUrl}/${groupId}/members/identities`,
      "DELETE",
      identityIds
    );
  }

  /**
   * Workaround for searching groups and filtering for certain properties
   * See: https://gitlab.cern.ch/authzsvc/AuthorizationServiceApi/issues/22
   * @param {string} queryParams the params such as filter, etc
   */
  async searchOneGroup(groupId, queryParams) {
    const groupResp = await this.getGroupById(groupId);
    const group = (await groupResp.json()).data;
    let params = {
      ...queryParams,
    };
    params.limit = 1;
    params.filter = `groupIdentifier:${group.groupIdentifier}`;
    return this.get(`${this.groupsUrl}`, params);
  }

  async logout() {
    return this.sendRequest(`${this.identityUrl}/logout`, "POST");
  }

  async getDynamicGroupCriteria() {
    return this.sendRequest(`${this.groupsUrl}/criteria`, "GET");
  }

  async getMyDynamicGroupCriteria() {
    return this.sendRequest(`${this.groupsUrl}/criteria/my`, "GET");
  }

  async patchComputingGroup(groupId, payload) {
    return this.sendRequest(`${this.groupsUrl}/${groupId}`, "PATCH", payload);
  }

  async createTokenExchangeRequest(target, source) {
    return this.put(
      `${this.registrationUrl}/${target}/token-exchange-request/${source}`
    );
  }

  async createTokenExchangeRequestWithBodyParameters(body) {
    return this.sendRequest(`${this.registrationUrl}`, "PUT", body);
  }

  async searchRegistrations(authenticationProviderId, queryParams) {
    return this.get(
      `${this.registrationUrl}/${authenticationProviderId}/search`,
      queryParams
    );
  }

  async getTokenExchangePermissions(registrationId, queryParams, actionType) {
    return this.sendRequest(
      `${this.registrationUrl}/${registrationId}/token-exchange-permission/${actionType}`,
      queryParams
    );
  }

  async deleteTokenExchangePermission(target, source) {
    return this.sendRequest(
      `${this.registrationUrl}/${target}/token-exchange-permission/${source}`,
      "DELETE"
    );
  }

  /**
   * Gets the current user's identity memberships (aka groups they belong to)
   */
  async getLevelsOfAssurance() {
    return this.sendRequest(this.levelOfAssuranceUrl, "GET");
  }

  /**
   * Patches a field in the identity object. The body must be a jsonPatch body
   */
  async patchIdentity(id, body) {
    return this.sendRequest(`${this.identityUrl}/${id}`, "PATCH", body);
  }

  async updateExternalEmail(id, email) {
    return this.sendRequest(
      `${this.identityUrl}/${id}/externalEmail?email=${email}`,
      "POST"
    );
  }

  /**
   * Transfer an Identity to another owner (for Service Identites)
   */
  async transferIdentity(identityId, otherIdentityId) {
    return this.sendRequest(
      `${this.identityUrl}/${identityId}/transfer/${otherIdentityId}`,
      "PUT"
    );
  }
  async getIdentitySubscriptions({ identityId }) {
    return this.sendRequest(
      `${this.identityUrl}/${identityId}/subscriptions`,
      "GET"
    );
  }

  async getCurrentIdentitySubscriptions() {
    return this.sendRequest(`${this.identityUrl}/current/subscriptions`, "GET");
  }

  async getMailProperties(id) {
    return this.sendRequest(`${this.mailPropertiesUrl}/Identity/${id}`, "GET");
  }

  async setMailProperties(id, body) {
    return this.sendRequest(
      `${this.mailPropertiesUrl}/Identity/${id}`,
      "POST",
      body
    );
  }

  /**
   * Checks if the mail identifier(s) are already taken or not
   * @param {string} identifierName The property to lookup, e.g. `alias`
   * @param {array} identifierValues Values to search
   */
  async searchMailPropertiesIdentifier(identifierName, identifierValues) {
    return this.sendRequest(
      `${this.mailPropertiesUrl}/identifierAvailability`,
      "POST",
      {
        identifierName,
        identifierValues,
      }
    );
  }

  async updateMailProperties(id, body) {
    return this.sendRequest(
      `${this.mailPropertiesUrl}/Identity/${id}`,
      "PUT",
      body
    );
  }

  async createMembershipRestriction(enforcedGroupId, sourceGroupId) {
    return this.sendRequest(
      `${this.groupsUrl}/${enforcedGroupId}/membershipRestrictions/sourceGroups/${sourceGroupId}`,
      "POST"
    );
  }

  async deleteMembershipRestriction(enforcedGroupId, sourceGroupId) {
    return this.sendRequest(
      `${this.groupsUrl}/${enforcedGroupId}/membershipRestrictions/sourceGroup/${sourceGroupId}`,
      "DELETE"
    );
  }

  async getMembershipRestrictionForGroup(enforcedGroupId) {
    return this.sendRequest(
      `${this.groupsUrl}/${enforcedGroupId}/membershipRestrictions/sourceGroups`,
      "GET"
    );
  }

  async deleteIdentityMembershipFromGroup(groupId, identityId) {
    return this.sendRequest(
      `${this.groupsUrl}/${groupId}/members/identities/${identityId}`,
      "DELETE"
    );
  }

  async activateOrBlockAccount(idOrUpn, blocked, reason) {
    return this.sendRequest(
      `${this.accountUrl}/${idOrUpn}/activateOrBlock`,
      "POST",
      {
        blocked,
        reason,
      }
    );
  }

  async activateOrBlockIdentity(idOrUpn, blocked, reason) {
    return this.sendRequest(
      `${this.identityUrl}/${idOrUpn}/activateOrBlock`,
      "POST",
      {
        blocked,
        reason,
      }
    );
  }

  async getAllGroupMailProperties(queryParams = {}) {
    return this.get(this.groupMailPropertiesUrl, queryParams);
  }

  async getGroupMailProperties(id) {
    return this.sendRequest(`${this.groupMailPropertiesUrl}/${id}`, "GET");
  }

  async updateGroupMailProperties(id, body) {
    return this.sendRequest(
      `${this.groupMailPropertiesUrl}/${id}`,
      "PUT",
      body
    );
  }

  async patchGroupMailProperties(id, body) {
    return this.sendRequest(
      `${this.groupMailPropertiesUrl}/${id}`,
      "PATCH",
      body
    );
  }

  async getGroupMailPropertiesForGroup(groupId) {
    return this.get(`${this.groupMailPropertiesUrl}/Group/${groupId}`);
  }

  async updateGroupMailPropertiesForGroup(groupId, body) {
    return this.sendRequest(
      `${this.groupMailPropertiesUrl}/Group/${groupId}`,
      "PUT",
      body
    );
  }

  async addGroupMailPropertiesIdentitiesAllowedToPost(groupId, identityIds) {
    return this.sendRequest(
      `${this.groupMailPropertiesUrl}/${groupId}/identitiesAllowedToPost`,
      "POST",
      identityIds
    );
  }

  async deleteGroupMailPropertiesIdentitiesAllowedToPost(groupId, identityIds) {
    return this.sendRequest(
      `${this.groupMailPropertiesUrl}/${groupId}/identitiesAllowedToPost`,
      "DELETE",
      identityIds
    );
  }

  async addGroupMailPropertiesGroupsAllowedToPost(groupId, groupIdentifiers) {
    return this.sendRequest(
      `${this.groupMailPropertiesUrl}/${groupId}/groupsAllowedToPost`,
      "POST",
      groupIdentifiers
    );
  }

  async deleteGroupMailPropertiesGroupsAllowedToPost(groupId, groupIds) {
    return this.sendRequest(
      `${this.groupMailPropertiesUrl}/${groupId}/groupsAllowedToPost`,
      "DELETE",
      groupIds
    );
  }

  async getGroupMembersCSV(groupId, compatibilityMode) {
    return this.get(
      `${this.groupsUrl}/${groupId}/members/csv`,
      compatibilityMode
    );
  }

  async putGroupMembersCSV(groupId, compatibilityMode, replace, file) {
    return this.sendRequest(
      `${this.groupsUrl}/${groupId}/members/csv?compatibilityMode=${compatibilityMode}&replace=${replace}`,
      "PUT",
      file,
      false
    );
  }
}

export { ApiError, AuthService };
